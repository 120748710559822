*
{
  font-family: "nanumSquare";
  list-style: none;
  text-decoration: none;
  border-collapse: collapse;
  margin: 0px;
  padding: 0px;
  color: #000;
}
@font-face {
  font-family: "nanumSquare";
  src: url(../chit/NanumFontSetup_OTF_SQUARE_ROUND/NanumSquareRoundOTFB.otf);
}
@keyframes slideup
{
  from{top: 55%}
  to{top:42%}
}
.bimg
{
  animation: fadein 1s;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.gradation_top_to_bottom
{
  animation: fadein 1s;
  background: linear-gradient(to bottom, #bcdfff 20%,white 90%);
  max-width: 100%;
  height: 100vh;
  overflow : hidden
}
.box
{
  animation: fadein 1s;
  transition: all 1s;
  position: relative;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 89%;
  height: 90%;
  border: 6px solid white;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.3);
  display : flex;
  justify-content: space-between;
  align-items:center;
  flex-direction: column;
}
.z11_question
{
  animation: fadein 1s;
  transition: all 0.3s;
  border: 6px solid white;  
  border-radius: 20px;
  background-color: rgba(255, 255,255,1);
  box-shadow: 0px 0px 15px rgba(0, 0,0,0.15);
  text-align: center;
  position: absolute;
  top:36%;
  left: 50%;
  width: 310px;
  height: 310px;
  transform: translate(-50%, -50%);
}
.warningText
{
  color: #3F3F3F;
  font-size: x-large;
  font-weight:900;
  text-align: center;
  position: relative;
  top: 12%;
  line-height: 150%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.WarnYes
{
  animation: fadein 1s;
  visibility: visible;
  text-align: center;
  position: absolute;
  top: 75%;
  left: 28%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  transition-duration: 0.1s;
  width: 120px;
  height: 60px;
  background-color: #FFBDA0;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.1);
}
.WarnNo
{
  animation: fadein 1s;
  visibility: visible;
  text-align: center;
  position: absolute;
  top: 75%;
  left: 72%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  transition-duration: 0.1s;
  width: 120px;
  height: 60px;
  background-color: #9CE7FF;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.1);
}
.WarnYes:active
{
  background-color: #F7AF90;
  box-shadow: none;
}
.WarnNo:active
{
  background-color: #8DDEF8;
  box-shadow: none;
}
.survey_box
{
  animation: fadein 1s;
  transition: all 1s;
  position: relative;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 89%;
  height: 90%;
  border: 6px solid white;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.3);
  background-color: rgba(255, 255,255,0.4);
}
.logo
{
  animation: fadslide 2s;
  transition: all 0.3s;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 260px;
  height: 260px;
  background-image: url(../chit/img/logo.svg);
}
@keyframes fadslide
{
  from{opacity: 0; top: 55%}
  to{opacity: 1; top: 42%;}
}
.del_logo
{
  animation: fadein 1s;
  transition: all 0.3s;
  position: absolute;
  top:42%;
  left: 50%;
  width: 240px;
  height: 80px;
  transform: translate(-50%, -50%);
  background-image: url(../chit/img/G1.svg);
}
.z1_question
{
  animation: fadein 1s;
  transition: all 0.3s;
  border: 6px solid white;  
  border-radius: 20px;
  background-color: rgba(255, 255,255,1);
  box-shadow: 0px 0px 15px rgba(0, 0,0,0.15);
  text-align: center;
  position: absolute;
  top:42%;
  left: 50%;
  width: 290px;
  height: 70px;
  transform: translate(-50%, -50%);
}
@keyframes fadein
{
  from{opacity: 0;}
  to{opacity: 1;}
}
@keyframes fadeout
{
  from{opacity: 1;}
  to{opacity: 0;}
}
.z2_question
{
  animation: fadein 1s;
  transition: all 0.2s;
  border: 6px solid white;
  border-radius: 20px;
  background-color: rgba(255, 255,255,1);
  box-shadow: 0px 0px 15px rgba(0, 0,0,0.15);
  text-align: center;
  position: absolute;
  top:42%;
  left: 50%;
  width: 290px;
  height: 100px;
  transform: translate(-50%, -50%);
}
.ques1z
{
  color: #3F3F3F;
  font-weight:900;
  font-size:x-large;
  transform: translate(0%,85%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.ques1zE
{
  color: #3F3F3F;
  font-weight:900;
  font-size:x-large;
  transform: translate(0%,77%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.ques2z
{
  color: #3F3F3F;
  line-height: 33px;
  font-weight:900;
  font-size:x-large;
  transform: translate(0%,55%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.ques2zE
{
  color: #3F3F3F;
  font-weight:900;
  font-size:x-large;
  transform: translate(0%, 17%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.ques3z
{
  color: #3F3F3F;
  line-height: 33px;
  font-weight:900;
  font-size:x-large;
  transform: translate(0%,55%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.ques3zE
{
  color: #3F3F3F;
  line-height: 33px;
  font-weight:900;
  font-size:x-large;
  transform: translate(0%,50%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFin
{
  color: #3F3F3F;
  font-weight:900;
  font-size:large;
  line-height: 24px;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinE
{
  color: #3F3F3F;
  font-weight:900;
  font-size:large;
  line-height: 24px;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFin2
{
  color: #3F3F3F;
  font-weight:900;
  font-size:large;
  line-height: 24px;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFin3
{
  color: #3F3F3F;
  font-weight:900;
  font-size:large;
  line-height: 24px;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFin4
{
  color: #3F3F3F;
  font-weight:900;
  font-size:large;
  line-height: 24px;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall2
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall3
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall4
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall5
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  line-height: 24px;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall6
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall7
{
  color: #3F3F3F;
  font-weight:bolder;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinSmall8
{
  color: #3F3F3F;
  font-weight:bolder;
  line-height: 24px;
  font-size:large;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinTitle
{
  color: #3F3F3F;
  font-size: xx-large;
  font-weight:900;
  text-align: center;
  position: absolute;
  width: 85%;
  left: 50%;
  top: 20%; 
  transform: translate(-50%, -50%);
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinTitle_2
{
  color: #3F3F3F;
  font-size: xx-large;
  font-weight:900;
  text-align: center;
  position: absolute;
  width: 85%;
  left: 50%;
  top: 15%; 
  transform: translate(-50%, -50%);
  text-align: center;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinTitle_2_E
{
  color: #3F3F3F;
  font-size: xx-large;
  font-weight:900;
  text-align: center;
  position: absolute;
  width: 85%;
  left: 50%;
  top: 12.5%; 
  transform: translate(-50%, -50%);
  text-align: center;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinTitle_2_E_2Z
{
  color: #3F3F3F;
  font-size: xx-large;
  font-weight:900;
  text-align: center;
  position: absolute;
  width: 85%;
  left: 50%;
  top: 17.5%; 
  transform: translate(-50%, -50%);
  text-align: center;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.quesFinTitle_2_2
{
  color: #3F3F3F;
  font-size: xx-large;
  font-weight:900;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 25%; 
  transform: translate(-50%, -50%);
  text-shadow: 0.1px 0.1px 1px #9E9E9E;
}
.quesFinTitle_2_2_E
{
  color: #3F3F3F;
  font-size: xx-large;
  font-weight:900;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 27%; 
  transform: translate(-50%, -50%);
  text-shadow: 0.1px 0.1px 1px #9E9E9E;
}
.finBox
{
  animation: fadein 1s;
  transition: all 0.2s;
  border: 6px solid white;
  border-radius: 20px;
  line-height: 32px;
  background-color: rgba(255, 255,255,1);
  box-shadow: 0px 0px 15px rgba(0, 0,0,0.15);
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top:50%;
  left: 50%;
  width: 85%;
  height: 30%;
  transform: translate(-50%, -50%);
}
.finLBox
{
  animation: fadein 1s;
  transition: all 0.2s;
  border: 6px solid white;
  border-radius: 20px;
  background-color: rgba(255, 255,255,1);
  box-shadow: 0px 0px 15px rgba(0, 0,0,0.15);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top:50%;
  left: 50%;
  width: 85%;
  height: 30%;
  transform: translate(-50%, -50%);
}
.FcolorRed
{
  color: #D82C2C;
}
.FcolorRRed
{
  color: red;
}
.FcolorBlue
{
  color: #168FFF;
}
.FcolorOrange
{
  color: #D45C19;
}
.FcolorYellow
{
  color: #FFE600;
}
.FcolorGreen
{
  color: #20C81C;
}
.FcolorBeige
{
  color: #C6A370;
}
.z3_question
{
  animation: fadein 1s;
  transition: all 0.3s;
  position: absolute;
  border: 6px solid white;
  border-radius: 20px;
  background-color: rgba(255, 255,255,1);
  box-shadow: 0px 0px 15px rgba(0, 0,0,0.2);
  text-align: center;
  top:42%;
  left: 50%;
  width: 290px;
  height: 130px;
  transform: translate(-50%, -50%);
}

.um_yes
{
  visibility: hidden;
}
.um_no
{
  visibility: hidden;
}
.yes
{
  animation: fadein 1s;
  visibility: visible;
  text-align: center;
  position: absolute;
  top: 75%;
  left: 28%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  transition-duration: 0.1s;
  width: 120px;
  height: 60px;
  background-color: #FFBDA0;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.1);
}
.fin_yes
{
  display: none;
}
.yes:active
{
  background-color: #F7AF90;
  box-shadow: none;
}
.no
{
  animation: fadein 1s;
  visibility: visible;
  text-align: center;
  position: absolute;
  top: 75%;
  left: 72%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  transition-duration: 0.1s;
  width: 120px;
  height: 60px;
  background-color: #9CE7FF;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.1);
}
.no:active
{
  background-color: #8DDEF8;
  box-shadow: none;
}

@keyframes slideup_B
{
  from{top:85%}
  to{top:77%}
}

.start_box
{
  animation: fadein 2s;
  transition: all 0.3s;
  text-align: center;
  position: absolute;
  top: 77%;
  left: 50%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  transition-duration: 0.1s;
  width: 180px;
  height: 50px;
  background-color: #9CE7FF;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.1);

}
.del_start_box
{
  transition: all 0.3s;
  display: none;
}
.start_box:active
{
  background-color: #91E0F9;
  box-shadow: none;
}

.fin_start_box
{
  animation: fadein 0.7s;
  transition: all 0.3s;
  text-align: center;
  position: absolute;
  top: 80%;
  left: 50%;
  line-height: 1em;
  transform: translate(-50%, -50%);
  transition-duration: 0.1s;
  width: 180px;
  height: 50px;
  background-color: #9CE7FF;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.1);
}

.text_start
{
  transform: translate(0%, 20%);
  color:white;
  font-size: 190%;
  padding-bottom: 1px;
  font-weight: 600;
  letter-spacing: 1px;
}

.backButton
{
  line-height: 1em;
  position: absolute;
  top: 2%;
  left: 3%;
  width: 30px;
  height: 45px;
  border: 0;
  outline: 0;
  background-color: transparent;
  background-image: url(../chit/img/back.svg);
}
.warnn
{
  width: 100%;
  left: 50%;
  top: 71%; 
  transform: translate(-50%, -50%);
  animation: fadein 1s;
  transition: all 1s;
  text-align: center;
  color: #FC7168;
  font-size: x-small;
  position: absolute;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.warnnE
{
  width: 100%;
  left: 50%;
  top: 70.5%; 
  transform: translate(-50%, -50%);
  animation: fadein 1s;
  transition: all 1s;
  text-align: center;
  color: #FC7168;
  font-size: small;
  position: absolute;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.warnnE2Z
{
  width: 100%;
  left: 50%;
  top: 72.5%; 
  transform: translate(-50%, -50%);
  animation: fadein 1s;
  transition: all 1s;
  text-align: center;
  color: #FC7168;
  font-size: small;
  position: absolute;
  text-shadow: 0.1px 0.1px 0.5px #9E9E9E;
}
.G5Btn
{
  animation: fadein 0.7s;
  position: absolute;
  top: 87%;
  left: 12.5%;
  transform: translate(0%, 0%);
  width: 75%;
  height: 45px;
  background-color: transparent;
  border-radius: 55px;
  border: 0;
  outline: 0;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.15);
}
.G5Text
{
  animation: fadein 0.7s;
  transform: translate(0%, 0%);
  color:#636363;
  font-size: small;
  padding-bottom: 1px;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0.1px 0.1px 0.5px #494949;
}
.G5Btn:active
{
  opacity: 0.9;
}
.G5Text:active
{
  opacity: 0.9;
  box-shadow: none;
}
.G5Box
{
  animation: fadein 1s;
  position: relative;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 89%;
  height: 90%;
  border: 6px solid white;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0,0,0.3);
  background-color: rgba(255, 255,255,0.4);
}
.G5BackButton
{
  position: absolute;
  top: 2%;
  left: 3%;
  width: 30px;
  height: 45px;
  border: 0;
  outline: 0;
  background-color: transparent;
  background-image: url(../chit/img/back.svg);
}
.G5-1_ques_box
{
  position: absolute;
  top: 12%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: white;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-1_ques_box_clicked
{
  position: absolute;
  top: 12%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: #ECFFEC;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-2_ques_box
{
  position: absolute;
  top: 32%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: white;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-2_ques_box_clicked
{
  position: absolute;
  top: 32%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: #ECFFEC;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-3_ques_box
{
  position: absolute;
  top: 52%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: white;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-3_ques_box_clicked
{
  position: absolute;
  top: 52%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: #ECFFEC;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-4_ques_box
{
  position: absolute;
  top: 72%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: white;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
.G5-4_ques_box_clicked
{
  position: absolute;
  top: 72%;
  left: 10%;
  width: 80%;
  height: 18.7%;
  border: 0;
  border-radius: 20px;
  outline: 0;
  background-color: #ECFFEC;
  box-shadow: 1px 1px 10px 9px rgba(0, 0,0,0.03);
}
@keyframes upscroll
{
  from {height: 18%;}
  to {height: 99%;}
}
@keyframes FontOpacity_bef
{
  from{opacity: 1;}
  to{opacity: 0;}
}
@keyframes FontOpacity_aft
{
  from{opacity: 0;}
  to{opacity: 1;}
}
.G5Text_1_ques
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  text-align: center;
  font-size: small;
  color: #494949;
}
.G5Text_1_ques_clicked
{
  display: none;
}
.G5Text_1_ans
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  transform: translate(0%, 0%);
  line-height: 16px;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: small;
  color: #494949;
}
.G5Text_1_ans_clicked
{
  display: none;
}
.G5Text_2_ques
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  line-height: 18px;
  text-align: center;
  font-size: small;
  color: #494949;
}
.G5Text_2_ans
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  transform: translate(0%, 0%);
  line-height: 16px;
  position: relative;
  text-align: center;
  font-size: small;
  color: #494949;
  width: 100%;
}

.G5Text_3_ques
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  text-align: center;
  font-size: small;
  color: #494949;
}
.G5Text_3_ans
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  line-height: 16px;
  transform: translate(0%, 0%);
  text-align: center;
  position: relative;
  font-size: small;
  color: #494949;
}
.G5Text_4_ques
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  text-align: center;
  font-size: small;
  color: #494949;
}
.G5Text_4_ans
{
  animation: FontOpacity_aft;
  animation-fill-mode:forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  text-align: center;
  line-height: 16px;
  transform: translate(0%, 0%);
  font-size: small;
  color: #494949;
}
.korean
{
  animation: fadein 2s;
  transition: all 1s;
  margin-top: 5px;
  margin-left: 230px;
  width: 100px;
  height: 30px;
}
.english
{
  animation: fadein 2s;
  transition: all 1s;
  top:10%;
  left: 70%;
  width: 100px;
  height: 30px;
}
.PLAZENLOGO
{
  margin-top: 1%;
  float: left;
  margin-left: 1%;
  font-size: large;
  margin-bottom: 3%;
  animation: fadein 2s;
  transition: all 1s;
  color: #cfcfcf;
}
.PLAZENLOGO2
{
  margin-top: 1%;
  float: right;
  margin-right: 5%;
  font-size: large;
  margin-bottom: 3%;
  animation: fadein 2s;
  transition: all 1s;
  color: #cfcfcf;
}
.bottom_logo_space
{
  width: 100%;
}
.choi_logo
{
  float: left;
  margin-left: 5%;
  animation: fadein 2s;
  margin-bottom: 4%;
  transition: all 1s;
  width: 126px;
  height: 30px;
  background-image: url(../chit/img/choi_Dent_Last.svg);
}
.choi_logo2
{
  float: right;
  margin-right: 3%;
  margin-bottom: 4%;
  animation: fadein 2s;
  transition: all 1s;
  width: 126px;
  height: 30px;
  background-image: url(../chit/img/P_RCT.svg);
}